import NiceSelect from "/js/plugins/nice-select2.js"

const searchableSelects = document.querySelectorAll('[data-ksh-type="liveSearchSelect"]')

// This function HAS to be called somewhere in order for the form dropdowns to be replaced...

const allNiceSelects = []

const addNiceSelect = (selectBox) => {
    let placeholder = 'Select One...'
    if (selectBox.dataset.kshPlaceholder) {
        placeholder = selectBox.dataset.kshPlaceholder
    }
    // const placeholder = selectBox.dataSet.kshPlaceholder
    const searchableSelectBox = new NiceSelect(selectBox, {searchable: true, placeholder: placeholder})

    allNiceSelects.push(searchableSelectBox)
}

const makeNicerSelects = () => {
    for (let selectBox of searchableSelects) {

        addNiceSelect(selectBox)
    }
    return allNiceSelects
}


// ...so we call it here, and also get an array of all NiceSelects on the page. Neat!
const niceSelects = makeNicerSelects()

/**
 * Update options in a given selection field in a way that plays nice with our niceSelect plugin
 * @param {Object} niceSelectField - Pass in the NiceSelect object you wish to update the option list for. 
 * @param {Array} optionList - Pass in an array of objects with the format [{
 * value: 'option value', innerText: 'option inner text' }, ...]
 */

const updateOptions = (niceSelectField, optionList) => {
    niceSelectField.el.innerHTML = ""
    for (let option of optionList) {
        const newOption = document.createElement('option')
        newOption.value = option.value
        newOption.innerHTML = option.innerText
        niceSelectField.el.appendChild(newOption)
        niceSelectField.update()
    }

}

const getPairedNiceSelect = (selectField) => {
    let success = false
    for (let field of niceSelects) {
        if (field.el === selectField ) {
            success = true
            return field
        }
    } 
    if (!success) {
        console.log("no corresponding NiceSelect field was found")
    }
}

export { niceSelects, updateOptions, getPairedNiceSelect, addNiceSelect}